

















































import { convertePeriodoParaDatas, Periodo, PeriodoPreDefinido } from '@/shareds/date/date-utils'
import moment from 'moment'
import { Vue, Component, PropSync, Ref } from 'vue-property-decorator'
import DatePicker from '@/components/ui/DatePicker.vue'
import { obrigatorio } from '@/shareds/regras-de-form'

@Component({
	components: {
		DatePicker,
	},
})
export default class SeletorDePeriodo extends Vue {
	@Ref() formDePeriodo!: HTMLFormElement
	@PropSync('value', { type: [Array, String], default: null }) periodo!: Periodo | null	

	mostraEdicao = false

	obrigatorio = obrigatorio

	datasEmEdicao = convertePeriodoParaDatas(this.periodo || 'Hoje')

	get periodoPreDefinido() {
		if (!this.periodo) return 'Hoje'
		if (Array.isArray(this.periodo)) return 'Outros'
		return this.periodo
	}

	set periodoPreDefinido(periodoPreDefinido: PeriodoPreDefinido) {
		const periodo = periodoPreDefinido === 'Outros'
			? convertePeriodoParaDatas(this.periodoPreDefinido)
			: periodoPreDefinido
		this.periodo = periodo
		this.$emit('input', periodo)
	}

	get datasDoPeriodoFormatadas() {
		const datas = this.periodoPreDefinido === 'Outros'
			? this.periodo as [string, string | null]
			: convertePeriodoParaDatas(this.periodo || 'Hoje')
		const [dataInicial, dataFinal] = datas.map(data => data && moment(data).format('DD/MM/YYYY'))
		return [dataInicial, dataFinal || 'Fim'].join(' &ndash; ')
	}	

	abrirEdicaoDeDatas() {
		this.mostraEdicao = true
		this.datasEmEdicao = convertePeriodoParaDatas(this.periodo || 'Hoje')
		window.requestAnimationFrame(() => {
			this.formDePeriodo.resetValidation()
		})
	}

	confirmarPeriodo() {
		if (!this.formDePeriodo.validate()) return
		this.$emit('input', this.datasEmEdicao)
		this.mostraEdicao = false
	}
}

