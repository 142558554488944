

































import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { Vue, Component, Prop } from 'vue-property-decorator'
import SeletorDePeriodo from '../dashboards/SeletorDePeriodo.vue'
import { serializarQueryString } from '@/common/request'
import { backendDomain } from '@/shareds/utils'
import { convertePeriodoParaDatas, Periodo } from '@/shareds/date/date-utils'
import moment from 'moment'

@Component({
	components: {
		SeletorDePeriodo,
	},
})
export default class DialogoDePeriodo extends Vue  {
	@Prop({ type: String }) idDeposito!: string | null

	mostra = false


	mostrar(){
		return this.mostra = true
	}

	get filtros () {
		const [dataInicial, dataFinal ] = convertePeriodoParaDatas(this.periodo || 'Hoje')
		const filtros = {
			dataInicial: moment(dataInicial).toISOString(true),
			dataFinal: dataFinal ? moment(dataFinal).endOf('day').toISOString(true) : undefined,
			idDeposito: this.idDeposito,
		}
		return filtros
	}

	get urlDeCurvaAbc(){
		if (!UserLoginStore.token || !this.idDeposito) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/relatorio-curva-abc/CurvaAbc.csv?${queryString}`
	}

	get periodo() {
		const periodoDaQuery = this.$route.query.d
		if (typeof periodoDaQuery === 'string' && moment(periodoDaQuery).isValid()) {
			return [periodoDaQuery, null]
		}
		if (Array.isArray(periodoDaQuery) && periodoDaQuery.length === 1 ) {
			return [periodoDaQuery[0] as string, null]
		}
		return (periodoDaQuery as Periodo) || null
	}

	set periodo(periodo: Periodo | null) {
		this.$router
			.replace({
				name: this.$route.name as string,
				query: {
					...this.$route.query,
					d: Array.isArray(periodo)
						? periodo.filter(periodo => !!periodo)
						: periodo,
				},
			})
	}

}
