




























import { ListagemItensRomaneio } from '@/models/Romaneio'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { ItemRomaneioEstoqueUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'

@Component
export default class DialogoDeDetalhesDaTransferencia extends Vue {
	mostra = false
	carregando = false
	ramaneioId: string | null = null
	listagemItem: ListagemItensRomaneio | null = null

	itemRomaneioEstoqueUseCase = new ItemRomaneioEstoqueUseCase()
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	cancelToken: CancelTokenSource | null = null

	headers = [
		{ text: 'Nome', value: 'nome' },
		{ text: 'SKU', value: 'sku' },
		{ text: 'EAN', value: 'ean' },
		{ text: 'Quantidade', value: 'quantidade' },
	]

	async mostrar(romaneioId: string) {
		this.ramaneioId = romaneioId
		this.mostra = true
		this.buscar()
	}

	@Watch('paginacao')
	async buscar() {
		if(!this.ramaneioId) return
		this.carregando = true
		this.cancelToken = axios.CancelToken.source()

		const params = {
			romaneioId: this.ramaneioId,
			page: this.paginacao.page - 1,
			size: this.paginacao.itemsPerPage,
			sort: 'createdAt,desc',
		}

		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		try {
			const pagina = await this.itemRomaneioEstoqueUseCase.findItensRomaneio(
				params,
				axiosConfig,
			)
			this.listagemItem = pagina
			this.totalRegistros = pagina.totalElementos			
		} catch(e) {
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}

	}

	get itensFormatados() {
		return (
			this.listagemItem 
				? this.listagemItem.itens.map(item => ({
					...item,
					nome: item.produto.nome, 
					ean: item.produto.eans[0],
					sku: item.produto.sku,
				})) || []
				: []
		)
	}

	@Watch('mostra', { deep: true })
	onChangeMostrar() {
		if(!this.listagemItem) return
		this.listagemItem.itens = []
		this.listagemItem.totalElementos = 0
		this.listagemItem.quantidadeTotalDosItens = 0
	}
}
