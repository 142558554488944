










































import { ComparacaoEstoque, EstoqueGenerico } from '@/models'
import { CancelTokenSource } from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { DataOptions } from 'vuetify'

@Component
export default class DialogoDeComparacaoDeEstoque extends Vue {
	mostra = false
	carregando = false
	listagemFormatada!: ComparacaoEstoque[]
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	cancelToken: CancelTokenSource | null = null

	headers = [
		{ text: 'Nome', value: 'nome' },
		{ text: 'SKU', value: 'sku' },
		{ text: 'Quantidade Lido', value: 'qtdeArquivo' },
		{ text: 'Disponível em Estoque', value: 'qtdeDisponivel' },
		{ text: 'Diferença', value: 'diferenca' },
	]

	async mostrar(listagemA: EstoqueGenerico[], listagemB: EstoqueGenerico[], isParcial: boolean) {
		this.mostra = true
		const listagemCompletaDeSku: string[] = []

		if (isParcial) {
			listagemB.forEach(item => {
				if (!listagemCompletaDeSku.includes(item.sku) && item.sku) {
					listagemCompletaDeSku.push(item.sku)
				}
			})
		} else {
			listagemA.forEach(item => {
				if (!listagemCompletaDeSku.includes(item.sku) && item.sku) {
					listagemCompletaDeSku.push(item.sku)
				}
			})

			listagemB.forEach(item => {
				if (!listagemCompletaDeSku.includes(item.sku) && item.sku) {
					listagemCompletaDeSku.push(item.sku)
				}
			})
		}

		this.listagemFormatada = listagemCompletaDeSku.map(item => {
			const estoqueA = listagemA.filter(itemA => itemA.sku === item)
			const estoqueB = listagemB.filter(itemB => itemB.sku === item)

			if (estoqueA.length > 0 && estoqueB.length > 0) {
				return {
					nome: estoqueA[0].nome, 
					sku: estoqueA[0].sku,
					qtdeArquivo: Number(estoqueB[0].quantidade),
					qtdeDisponivel: estoqueA[0].quantidade,
					diferenca: Math.abs(estoqueA[0].quantidade as number - estoqueB[0].quantidade as number),
				} as ComparacaoEstoque
			} else if (estoqueA.length > 0 && estoqueB.length === 0) {
				return {
					nome: estoqueA[0].nome, 
					sku: estoqueA[0].sku,
					qtdeArquivo: 0,
					qtdeDisponivel: estoqueA[0].quantidade,
					diferenca: Math.abs(estoqueA[0].quantidade),
				} as ComparacaoEstoque
			} else if (estoqueA.length === 0 && estoqueB.length > 0) {
				return {
					nome: 'Produto novo neste deposito', 
					sku: estoqueB[0].sku,
					qtdeArquivo: Number(estoqueB[0].quantidade),
					qtdeDisponivel: 0,
					diferenca: Math.abs(estoqueB[0].quantidade as number),
				} as ComparacaoEstoque
			} else {
				return {
					nome: '', 
					sku: '',
					qtdeArquivo: 0,
					qtdeDisponivel: 0,
					diferenca: 0,
				} as ComparacaoEstoque
			}
		})
	}
}
