import { Page } from "@/models";
import { ItemDeRomaneio, Romaneio, RomaneioForm } from "@/models/Romaneio";
import { Inject } from "inversify-props";
import type { EstoqueServiceAdapter } from "../deposito/adapter";
import { AxiosRequestConfig } from "axios";

export class RomaneioEstoqueUseCase {

	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	async salvarRomaneio(romaneioForm: RomaneioForm): Promise<Romaneio> {
		return this.estoqueService.salvarRomaneio(romaneioForm)
	}

	async findRomaneioPendente(depositoId: string, params?: any, config?: AxiosRequestConfig): Promise<Page<Romaneio>> {
		return this.estoqueService.findRomaneio(depositoId, params, config)
	} 

	async update(romaneio: RomaneioForm): Promise<void> {
		return this.estoqueService.update(romaneio)
	}

	async montarItens(urlDoArquivoCsv: string): Promise<ItemDeRomaneio[]> {
		return this.estoqueService.montarItens(urlDoArquivoCsv)
	}

	async atualizar(romaneio: RomaneioForm): Promise<void> {
		return this.estoqueService.atualizar(romaneio)
	}
}
