
import type { EstoqueServiceAdapter } from "@/usecases/deposito";
import { Inject } from "inversify-props";


export class ExportAllItensEstoqueUseCase {

	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	exportEstoque(idTabela: string, layoutExportado: string): Promise<any> {
		return this.estoqueService.exportEstoque(idTabela, layoutExportado)
	}
}