
















































































import Confirmacao from '@/components/ui/Confirmacao.vue'
import { ItemDeRomaneioForm, Romaneio } from '@/models/Romaneio'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { RomaneioEstoqueUseCase } from '@/usecases/loja/RomaneioEstoqueUseCase'
import axios, { CancelTokenSource } from 'axios'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import DialogoDeDetalhesDaTransferencia from './DialogoDeDetalhesDaTransferencia.vue'

@Component({
	components: {
		DialogoDeDetalhesDaTransferencia,
		Confirmacao,
	},
})
export default class DialogoDeSelecaoDeBalanco extends Vue {
	@Ref() readonly detalhesTranferencia!: DialogoDeDetalhesDaTransferencia
	@Ref() confirmacaoDaMovimentacao!: Confirmacao

	mostra = false
	romaneio: Romaneio[] = []
	itemRomaneio: ItemDeRomaneioForm[] = []
	romaneioEstoqueUseCase = new RomaneioEstoqueUseCase()
	cancelToken: CancelTokenSource | null = null
	depositoId: string | null = null
	buscando = false
	carregando = false

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	headers = [
		{ text: 'Identificador', value: 'sequenciaNumerica' },
		{ text: 'Status', value: 'statusRomaneio' },
		{ text: 'Data e Hora', value: 'dataHoraCriacao' },
		{ text: 'Tipo de movimentação', value: 'tipoMovimentacaoRomaneio' },
		{ text: 'observacao de Romaneio', value: 'observacaoRomaneio' },
		{ text: 'Loja Origem', value: 'deposito.loja.nomeFantasia'},
		{ text: 'Ações', value: 'actions' },
	]

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	get itensFormatados() {
		
		return this.romaneio.map(romaneio => ({
			...romaneio,
			dataHoraCriacao: !romaneio.dataHoraCriacao ? '-' : dateTimeToPtBrFormat(romaneio.dataHoraCriacao),
			observacaoRomaneio: romaneio.observacaoRomaneio 
				? romaneio.observacaoRomaneio 
				: 'Sem Observação',
		}))
	}

	formatarMenssagem(romaneioSelecionado: Romaneio) {
		if(romaneioSelecionado.zerarTodoEstoque == true) {
			return `
					<div><h2 class="formatarAviso">Aviso!</h2></div>
					<div class="mt-4">
						Essa movimentação está com a opção "zerar todo estoque" selecionada.
						Esse processo irá zerar todo estoque dos produtos ausentes na lista.
					</div>
					<div class="d-flex justify-end mt-8" style="font-size: 11px">${'Identificador: ' + romaneioSelecionado.sequenciaNumerica}</div>
				`
		} 
		return `<div>${'Identificador: ' + romaneioSelecionado.sequenciaNumerica}</div>`
	}

	async mostrar(idDeposito?: string) {
		this.depositoId = idDeposito || null
		this.mostra = true
		this.buscarRomaneiosPendentes()
	}

	@Watch('paginacao')
	async buscarRomaneiosPendentes() {
		if(!this.depositoId) return
		this.buscando = true
		this.cancelToken = axios.CancelToken.source()

		const params = {
			page: this.paginacao.page - 1,
			size: this.paginacao.itemsPerPage,
			sort: 'createdAt,desc',
			tipoRomaneio: 'BALANCO',
		}
		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		try {
			const pagina = 
				await this.romaneioEstoqueUseCase.findRomaneioPendente(
					this.depositoId,
					params,
					axiosConfig,
				)

			this.romaneio = pagina.content
			
			this.totalRegistros = pagina.totalElements
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	async selecionar(romaneioSelecionado: Romaneio) {
		const romaneio = this.romaneio.filter(r => r.id === romaneioSelecionado.id)[0]
		romaneioSelecionado.dataHoraCriacao = romaneio.dataHoraCriacao
		this.$emit('confirmarRomaneio', romaneioSelecionado)
		this.mostra = false
	}

	@Watch('mostra', { deep: true })
	onChangeMostrar() {
		this.romaneio = []
	}
}
