var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" d-flex"},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.horas[0]),callback:function ($$v) {_vm.$set(_vm.horas, 0, $$v)},expression:"horas[0]"}},'v-text-field',Object.assign({}, attrs, _vm.inicial),false),on))]}}]),model:{value:(_vm.mostraMenu1),callback:function ($$v) {_vm.mostraMenu1=$$v},expression:"mostraMenu1"}},[(_vm.mostraMenu1)?_c('v-time-picker',_vm._b({attrs:{"format":"24hr","full-width":""},on:{"click:minute":function () {
				_vm.menu1.save()
				_vm.mostraMenu2 = true
			}},model:{value:(_vm.horas[0]),callback:function ($$v) {_vm.$set(_vm.horas, 0, $$v)},expression:"horas[0]"}},'v-time-picker',_vm.datePickerInicial,false)):_vm._e()],1),_c('div',{staticClass:"align-self-center px-3"},[_vm._v("–")]),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":""},model:{value:(_vm.horas[1]),callback:function ($$v) {_vm.$set(_vm.horas, 1, $$v)},expression:"horas[1]"}},'v-text-field',Object.assign({}, attrs, _vm.final),false),on))]}}]),model:{value:(_vm.mostraMenu2),callback:function ($$v) {_vm.mostraMenu2=$$v},expression:"mostraMenu2"}},[(_vm.mostraMenu2)?_c('v-time-picker',_vm._b({attrs:{"format":"24hr"},on:{"click:minute":function () { return _vm.menu2.save(); }},model:{value:(_vm.horas[1]),callback:function ($$v) {_vm.$set(_vm.horas, 1, $$v)},expression:"horas[1]"}},'v-time-picker',_vm.datePickerFinal,false)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }