






























































import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { FormDeConversaoDeEstoque } from '@/models/estoque/ConversaoDeEstoque'
import type { TipoDeConversao } from '@/models/estoque/ConversaoDeEstoque'
import type { ProdutoComposto } from '../../models'
import { ItemDoProdutoComposto, Deposito } from '../../models'
import { ConversaoDeEstoqueUseCase } from '@/usecases'
import { DepositoModule } from '@/store/vuex/deposito/DepositoStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { maiorQueZero, valorInteiro, obrigatorio } from '@/shareds/regras-de-form'

@Component({
	components: {
		BuscaDeProdutoDropdown,
	},
})
export default class DialogoDeConversaoProduto extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() buscaDeProduto!: BuscaDeProdutoDropdown
	@Prop({ type: String }) deposito!: string
	@Prop({ type: String, default: 'montagem' as TipoDeConversao })
	operacao!: TipoDeConversao
	maiorQueZero = maiorQueZero
	valorInteiro = valorInteiro
	obrigatorio = obrigatorio

	mostra = false

	conversao = criarConversaoDeProduto(this.operacao)
	conversaoUseCase = new ConversaoDeEstoqueUseCase()
	qtdeMaximaProduto = 0

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.qtdeMaximaProduto = 0
		this.conversao = criarConversaoDeProduto(this.operacao)
		setTimeout(() => {
			this.form.reset()
			setTimeout(() => {
				this.buscaDeProduto.focus()
			})
		})
	}

	@Watch('conversao.produto')
	onChangeProduto(produto: ProdutoComposto) {
		if (!produto) return
		this.qtdeMaximaProduto = 0
		const deposito = DepositoModule.depositoSelecionado
		if (!deposito) return
		if (this.operacao === 'montagem') {
			this.qtdeMaximaProduto =
				this.conversaoUseCase.qtdeMaximaMontagem(produto, deposito) | 0
		} else {
			this.qtdeMaximaProduto =
				deposito.itens.filter(item => item.produto.id === produto.id)[0]
					.qtdeEstoque | 0
		}
	}

	get reagentes(): ItemDoProdutoComposto[] {
		if (!this.conversao.produto) return []
		return this.conversao.operacao === 'montagem'
			? this.conversao.produto.itens
			: [
				{
					produto: { ...this.conversao.produto },
					quantidade: 1,
					desconto: this.conversao.desconto,
					idConjunto: this.conversao.idConjunto,
					tags: [],
				},
			]
	}

	get resultantes(): ItemDoProdutoComposto[] {
		if (!this.conversao.produto) return []
		return this.conversao.operacao === 'montagem'
			? [
				{
					produto: { ...this.conversao.produto },
					quantidade: 1,
					desconto: this.conversao.desconto,
					idConjunto: this.conversao.idConjunto,
					tags: [],
				},
			]
			: this.conversao.produto.itens
	}

	async convert() {
		if (!this.form.validate()) return
		try {
			let deposito: Deposito | undefined | null = DepositoModule.deposito
			if (!deposito) return
			if (this.conversao.operacao === 'montagem') {
				deposito = await this.conversaoUseCase.montar(deposito, this.conversao)
			} else if (this.conversao.operacao === 'desmontagem') {
				deposito = await this.conversaoUseCase.desmontar(
					deposito,
					this.conversao,
				)
			}
			if (!deposito) return
			DepositoModule.atualizaDeposito(deposito)
			this.mostra = false
			AlertModule.setSuccess('Conversão realizada!')
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	validarQtdeMaxima(valor: number) {
		return valor <= this.qtdeMaximaProduto || 'Valor maior que a quantidade máxima.'
	}
}

function criarConversaoDeProduto(
	operacao: TipoDeConversao = 'montagem',
): FormDeConversaoDeEstoque {
	return {
		operacao,
		produto: null,
		quantidade: null,
		desconto: {
			isPercentual: true,
			valor: 0,
		},
		idConjunto: null,
	}
}
