


































































































































































































































import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import {
	EstoqueDoProduto,
	FormProdutoBaseSimples,
	ItemDaVenda,
	Loja,
	PontoDeVenda,
	ProdutoProjection,
} from '@/models'
import {
	ItemDeRomaneio,
	ItemDeRomaneioForm,
	RomaneioForm,
} from '@/models/Romaneio'
import { converterProdutoParaProdutoSimples, displayNomeCompletoDoProduto, obterQuantidadePesadaNaBalanca } from '@/shareds/produto-shareds'
import { obrigatorio } from '@/shareds/regras-de-form'
import { uploadFilesToS3 } from '@/shareds/s3/files'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindConfiguracaoDaContaUseCase, FindLojaUseCase, FindProdutoUseCase } from '@/usecases'
import { RomaneioEstoqueUseCase } from '@/usecases/loja/RomaneioEstoqueUseCase'
import { CancelTokenSource } from 'axios'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import VueQuagga from "vue-quaggajs"

Vue.use(VueQuagga);

const BALANCO_ATUAL = 'BALANCO_ATUAL'

@Component({
	components: {
		CampoDeItemDeVenda,
		SeletorDeLojas,
		Confirmacao,
	},
})
export default class DialogoDeRomaneioDeEstoque extends Vue {
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda
	@Ref() confirmar!: Confirmacao 
	@Ref() uploadDeSkus!: HTMLInputElement

	obrigatorio = obrigatorio

	mostra = false
	loading = false
	tipoMovimentacao: string | null = null
	itens: ProdutoProjection | null = null
	produto: FormProdutoBaseSimples[] = []
	itemDeRomaneio: ItemDeRomaneio[] = []
	itemDeRomaneioForm: ItemDeRomaneioForm[] = []
	romaneio: RomaneioForm | null = null
	depositoId: string | null = null
	romaneioEstoqueUseCase = new RomaneioEstoqueUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()
	busca: string | null = null
	loja: Loja | null = null
	lojaSelecionada: string | null = null
	pontosDeVenda: PontoDeVenda[] = []
	cancelTokenDeposito: CancelTokenSource | null = null
	observacao: string | null = null
	depositoDestino: string | null = null
	findLojaUseCase = new FindLojaUseCase()
	buscaPorRomaneio = true
	zerarTodoEstoque = false
	showCamera = false
	mostrarDialogoLeituraDeCodigoBarras = false
	buscandoProduto = false
	valorLido: string | null = null
	itensDaVendaMontado: ItemDaVenda[] = []

	VendaModule = VendaModule
	balancoAtualStorage: ItemDeRomaneio[] = localStorage[BALANCO_ATUAL]
		? JSON.parse(localStorage[BALANCO_ATUAL])
		: []

	readerSize = {
		width: 860,
		height: 480,
	}

	headers = [
		{ text: 'Entrada', value: 'Entrada' },
		{ text: 'Saída', value: 'Saida' },
		{ text: 'Transferência', value: 'Transferencia' },
		{ text: 'Balanço', value: 'Balanco' },
	]

	get idUsuarioLogado(): string | undefined {
		return UserLoginStore.usuario?.id || undefined
	}

	get tipoTranferencia() {
		if (this.tipoMovimentacao == 'Transferencia') return true
		return false
	}

	async mostrar(idDeposito?: string, loja?: Loja) {
		this.depositoId = idDeposito || null
		this.loja = loja || null
		this.mostra = true
	}

	cancelar() {
		this.mostra = false

		if (this.tipoMovimentacao === 'Balanco') {
			localStorage.setItem(BALANCO_ATUAL, JSON.stringify([]))
		}

		this.itemDeRomaneio = []
		this.itemDeRomaneioForm = []
		this.tipoMovimentacao = ''
		this.lojaSelecionada = null
		this.observacao = ''
	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	
	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido && this.loja) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.loja.id,
				)

				const configuracaoDeConta = await this.findConfiguracaoDaContaUseCase.find()

				const quantidadeDeItens =
					produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida != 'MT'
						? 1
						: obterQuantidadePesadaNaBalanca(
							'',
							configuracaoDeConta?.medida || 6,
							configuracaoDeConta?.eans || 7,
							configuracaoDeConta?.casasNumericas || 4,
						)
				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: quantidadeDeItens,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco || 0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
				}

				this.incluirItens(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}

	async zerarEstoque() {
		if(this.zerarTodoEstoque) 
			return await this.confirmar.mostrar()

		this.concluirRomaneio()	
	}

	async concluirRomaneio() {
		this.loading = true
		this.itemDeRomaneio.forEach(item => {
			this.itemDeRomaneioForm.push({
				id: '',
				produto: item.produto.id || '',
				quantidade: item.quantidade,
				dataHoraCriacao: item.dataHoraCriacao,
			})
		})

		this.itemDeRomaneio.filter(item => !item.dataHoraCriacao).forEach(item => {
			item.dataHoraCriacao = new Date();
		})

		const listaDeDatas = this.itemDeRomaneio.filter(item => item.dataHoraCriacao !== null).map(item => item.dataHoraCriacao)
		const dataMaisAntiga = new Date(Math.min(...listaDeDatas.map(d => d ? new Date(d).getTime() : 0)))

		try {
			await this.buacarlojaSelecionada()
			const romaneioSalvar: RomaneioForm = {
				id: '',
				sequenciaNumerica: 0,
				tipoMovimentacaoRomaneio: this.tipoMovimentacao || '',
				usuarioCrio: this.idUsuarioLogado || '',
				usuarioConfirmou: '',
				statusRomaneio: 'Pendente',
				deposito: this.depositoId || '',
				dataHoraCriacao: dataMaisAntiga,
				itens: this.itemDeRomaneioForm,
				depositoDestino: this.depositoDestino 
					? this.depositoDestino 
					: this.depositoId || '',
				observacaoRomaneio: this.observacao || '',
				zerarTodoEstoque: this.zerarTodoEstoque,
			}

			const romaneioSalvo = await this.romaneioEstoqueUseCase.salvarRomaneio(romaneioSalvar)

			let mensagemSucesso = ''

			switch (this.tipoMovimentacao) {
				case 'Saida':
					mensagemSucesso = 'Saída de estoque concluída com sucesso.'
					break
				case 'Entrada':
					mensagemSucesso = 'Entrada de estoque concluída com sucesso.'
					break
				default:
					mensagemSucesso = 'Romaneio criado com sucesso.'
			}

			if (this.tipoMovimentacao === 'Transferencia' || this.tipoMovimentacao === 'Balanco') {
				mensagemSucesso += ` Identificador: ${romaneioSalvo.sequenciaNumerica}`
			}

			if (this.tipoMovimentacao === 'Balanco') {
				localStorage.setItem(BALANCO_ATUAL, JSON.stringify([]))
			}

			AlertModule.setSuccess(mensagemSucesso)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.itemDeRomaneio = []
			this.itemDeRomaneioForm = []
			this.tipoMovimentacao = ''
			this.itemDeRomaneioForm = []
			this.lojaSelecionada = null
			this.observacao = ''
			this.loading = false
			this.mostra = false
			this.zerarTodoEstoque = false
			this.depositoDestino = null
		}
	}

	async buacarlojaSelecionada() {
		if (!this.lojaSelecionada) return
		try {
			const axiosConfig = {
				cancelToken: this.cancelTokenDeposito?.token,
			}

			const depositoPage = await this.findLojaUseCase.findDepositoDaLojaById(
				this.lojaSelecionada,
				axiosConfig,
			)

			if (!depositoPage.content[0])
				throw new Error('Deposito da loja não encontrado')
			this.depositoDestino = depositoPage.content[0].id || ''
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async incluirItens(item: ItemDaVenda) {
		this.loading = true
		if (!item.produto.eans[0]) return
		if (!this.loja) {
			AlertModule.setError('Selecione uma loja!')
			return
		}
		try {
			const itemDeRomaneioNovo: ItemDeRomaneio = {
				id: '',
				produto: {
					id: item.produto.id,
					nome: displayNomeCompletoDoProduto(item.produto),
					tipo: item.produto.tipo,
					preco: item.produto.preco,
					pesoLiquido: item.produto.pesoLiquido,
					pesoBruto: item.produto.pesoBruto,
					eans: item.produto.eans,
					sku: item.produto.sku || '',
					identificadores: item.produto.identificadores,
					nomeCompleto: item.produto.nomeCompleto,
					produtoDesativado: item.produto.produtoDesativado,
					descontoVarejo: item.produto.descontoVarejo,
					isValePresente: item.produto.isValePresente,
					urlImagens: item.produto.urlImagens,
					skuPai: item.produto.skuPai,
				},
				quantidade: 1,
				dataHoraCriacao: new Date(),
			}

			const indiceDoItemBipado = this.itemDeRomaneio.findIndex(
				item => item.produto.sku === itemDeRomaneioNovo.produto.sku,
			)
			const itemAnterior = this.itemDeRomaneio[indiceDoItemBipado]
			const quantidade =
				indiceDoItemBipado === -1
					? item.quantidade
					: itemAnterior.quantidade + item.quantidade

			const quantidadeEmEstoque = await this.findProdutoUseCase.findConsultaEstoqueDeProduto(item.produto.id, this.loja.id) as EstoqueDoProduto[];

			let autenticar = false
			const podeGerarEstoqueNaLoja = this.loja.configuracaoDaLoja.podeGerarEstoqueNaVenda
			const podeGerarEstoqueNoPerfil = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque')

			if((this.tipoMovimentacao === 'Entrada' || this.tipoMovimentacao === 'Balanco') 
			|| ((this.tipoMovimentacao === 'Saida' || this.tipoMovimentacao === 'Transferencia') 
			&& ((podeGerarEstoqueNaLoja || podeGerarEstoqueNoPerfil) || quantidadeEmEstoque[0] && quantidadeEmEstoque[0].disponivel > 0 && quantidade <= quantidadeEmEstoque[0].disponivel)))
			{
				autenticar = true
			}else{
				const mensagemDeErro = !quantidadeEmEstoque[0] ? 'Produto não possui estoque parametrizado' : 'Quantidade do produto excede o disponivel!'
				AlertModule.setError(mensagemDeErro)
				autenticar = !!await VendaModule.autenticarAcaoTelaDeCaixa({ regra: 'pode-liberar-produto-sem-estoque' })
			}

			if(this.tipoMovimentacao !== 'Balanco' && this.tipoMovimentacao !== 'Entrada' && !autenticar) {	
				await this.findProdutoUseCase.findProdutoComEstoqueRomaneio(
					item.produto.eans[0],
					this.loja.id,
					quantidade,
					this.tipoMovimentacao || "",
				)
			}

			if (indiceDoItemBipado === -1) {
				this.itemDeRomaneio.unshift(itemDeRomaneioNovo)

				if (this.tipoMovimentacao === 'Balanco') {
					this.balancoAtualStorage = this.itemDeRomaneio
					localStorage.setItem(BALANCO_ATUAL, JSON.stringify(this.balancoAtualStorage))
				}

				return
			}

			this.itemDeRomaneio.splice(indiceDoItemBipado, 1)

			this.itemDeRomaneio.unshift({
				...itemAnterior,
				quantidade: itemAnterior.quantidade + item.quantidade,
			})

			if (this.tipoMovimentacao === 'Balanco') {
				this.balancoAtualStorage = this.itemDeRomaneio
				localStorage.setItem(BALANCO_ATUAL, JSON.stringify(this.balancoAtualStorage))
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)
			this.loading = false
		}
	}

	deleteItem(item: any) {
		const idx = this.itemDeRomaneio.indexOf(item)
		if (idx !== -1) {
			this.itemDeRomaneio.splice(idx, 1)
		}
	}

	async subirSkus(event: Event) {
		if (!event.target) return;
		const fileInput = event.target as HTMLInputElement;
		if (!fileInput.files || fileInput.files.length == 0) return;
		const reader = new FileReader();
		
		reader.onload = async (evt) => {
			if (!evt.target) return;
			if (evt.target.readyState != 2) return;
			if (evt.target.error) {
				throw('Erro ao ler o arquivo');
			}
			if (!evt.target.result) return;
			if (!fileInput.files || fileInput.files.length == 0) return;
			const file = fileInput.files[0];
			
			const informacoesDosArquivos = await uploadFilesToS3(
				[file],
				`importacao-de-clientes`,
			);
			
			const urlDoArquivoCsv = informacoesDosArquivos.map(({ config }) => config.url)[0];
			if (!urlDoArquivoCsv) {
				return AlertModule.setError("Ocorreu um erro interno, contate o suporte");
			}
			
			this.itemDeRomaneio = await this.romaneioEstoqueUseCase.montarItens(urlDoArquivoCsv)
				.then(itensDoRomaneioMontado => {
					return itensDoRomaneioMontado
				})
				.catch(error => {
					AlertModule.setError(error)
					return []
				})
		}
		reader.readAsText(fileInput.files[0])
	}

	@Watch('mostra', { deep: true })
	onChangeMostra() {
		this.balancoAtualStorage = localStorage[BALANCO_ATUAL]
			? JSON.parse(localStorage[BALANCO_ATUAL])
			: [] as ItemDeRomaneio[]
		
		if (this.mostra && this.balancoAtualStorage.length > 0) {
			this.itemDeRomaneio = this.balancoAtualStorage
			this.tipoMovimentacao = 'Balanco'
		}
	}
}
