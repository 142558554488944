



































































import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator'

@Component
export default class RangerTimePicker extends Vue {
	@PropSync('value') horas!: (string | null)[]
	@Prop() inicial!: any
	@Prop() final!: any
	@Prop() datePickerInicial!: any
	@Prop() datePickerFinal!: any
	@Ref() menu1!: {
		save: (value?: any) => void
	}
	@Ref() menu2!: {
		save: (value?: any) => void
	}
	
	mostraMenu1 = false
	mostraMenu2 = false

	
}

