


























































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import Papa from 'papaparse'

@Component
export default class ImportadorDeCsv<DataType> extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({ type: Array, default: () => [] }) data!: DataType | null
	@Prop({ 
		type: Function,
		default: undefined,
	}) validacao?: (data: any[]) => string

	@Ref() fileInput!: {
		$el: HTMLInputElement
	}
	@Prop({ type: Array, default: () => [] }) headers!: HeadersType[]
	
	mostra = false
	mostraArquivo = false
	mostraProcessado = false
	processou = false

	errors: string[] = []
	carregando = false
	search = ''

	file: File | null = null
	dataTable: any[] = []

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.mostraArquivo = true
		this.mostraProcessado = false
		setTimeout(() => {
			const input = this.fileInput.$el.querySelector('input')
			if (!input) return
			input.click()
		})
	}

	mostrar() {
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}

	mostraFile() {
		this.mostraArquivo = true
		this.mostraProcessado = false
		this.dataTable = []
	}

	load() {
		if (!this.file) return
		this.carregando = true
		this.errors = []

		Papa.parse(this.file, {
			header: true,
			skipEmptyLines: true,
			complete: (result) => this.fetchCsvData(result),
		})
		this.carregando = false
		this.processou = true
		this.mostraProcessado = true
		this.mostraArquivo = false
	}

	//@ts-ignore
	fetchCsvData(result: Papa.ParseResult<any>) {
		const data = result.data
		if (result.errors.length) return
		const resultado: any[] = data
		this.dataTable = resultado
		if (this.validacao) {
			const erro = this.validacao(this.dataTable)
			erro && this.errors.push(erro)
		}
	}

	get colunas() {
		return [
			...this.headers,
			{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
		]
	}

	get dados() {
		return this.dataTable
	}

	importar() {
		if (!this.dataTable) return
		this.$emit('importar', this.dataTable)
	}

	deleteItem(item: any) {
		const idx = this.dataTable.indexOf(item)
		if (idx !== 1) {
			this.dataTable.splice(idx, 1)
		}
	}

	get erros() {
		return this.errors
			? this.errors[0]
			: ''
	}
}

export interface HeadersType {
	value: string
	text: string
}

