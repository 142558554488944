var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_vm._v("Selecione o balanço")]),_c('v-data-table',_vm._g(_vm._b({staticClass:"elevation-1",attrs:{"items":_vm.itensFormatados,"headers":_vm.headers,"server-items-length":_vm.totalRegistros,"options":_vm.paginacao,"manterPaginas":"","must-sort":"","loading":_vm.buscando},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.observacaoRomaneio",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-romaneio"},on),[_vm._v(" "+_vm._s(item.observacaoRomaneio)+" ")])]}}],null,true)},[_c('div',{staticClass:"formatarObservacao"},[_c('span',[_vm._v(_vm._s(item.observacaoRomaneio))])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.detalhesTranferencia.mostrar(item.id); }}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[(item.tipoMovimentacaoRomaneio == 'Balanco')?_c('span',[_vm._v(" Detalhar Balanço ")]):_c('span',[_vm._v(" Detalhar Romaneio ")])]),_c('Confirmacao',{attrs:{"width":"300","titulo":"Selecionar balanço de estoque?","subtitulo":_vm.formatarMenssagem(item),"opcaoPadrao":"Não"},on:{"confirmar":function () { return _vm.selecionar(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","loading":_vm.buscando}},Object.assign({}, tooltip, on)),[_vm._v(" mdi-check-underline ")])]}}],null,true)},[_c('span',[_vm._v("Selecionar")])])]}}],null,true)})]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1),_c('DialogoDeDetalhesDaTransferencia',{ref:"detalhesTranferencia"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }